import { InputNumber } from 'components/InputNumber/InputNumber';
import { RangeSlider } from 'components/RangeSlider/RangeSlider';
import { Select } from 'components/Select/Select';
import React from 'react';
import { Balcon, Tree } from 'shared/svg/filters';
import { toNumber } from 'utils/filters';
import { useFilters } from './useFilters';
import {
    Button,
    ButtonsWrapper,
    FilterBox,
    FiltersBox,
    IconWrapper,
    InputWrapper,
    Label,
} from './TableFilters.styled';

interface ITableFiltersProps {
    clearFiltersActive: boolean;
    onHandleClearFilters: () => void;
    buildingInitial: string[];
    floorInitial: string[];
    roomInitial: string[];
    yardageInitial: string[];
    priceInitial: string[];
    facilitiesInitial: string[];
}

export const TableFilters = ({
    clearFiltersActive,
    onHandleClearFilters,
    buildingInitial,
    floorInitial,
    roomInitial,
    yardageInitial,
    priceInitial,
    facilitiesInitial,
}: ITableFiltersProps) => {
    const {
        control,
        facilities,
        handleOnBalconClick,
        handleOnGardenClick,
        handleOnMaxBlurPrice,
        handleOnMaxBlurYardage,
        handleOnMaxPriceChange,
        handleOnMaxYardage,
        handleOnMinBlurPrice,
        handleOnMinBlurYardage,
        handleOnMinPriceChange,
        handleOnMinYardage,
        handleOnPriceChange,
        handleOnYardageChange,
        isActiveButton,
        optionsBuilding,
        optionsFloor,
        optionsRoom,
        price,
        yardage,
    } = useFilters({
        clearFiltersActive,
        onHandleClearFilters,
        buildingInitial,
        floorInitial,
        roomInitial,
        facilitiesInitial,
        yardageInitial,
        priceInitial,
    });

    return (
        <FiltersBox>
            <FilterBox>
                <Select
                    options={optionsBuilding}
                    isMulti
                    name="investmentBuilding"
                    control={control}
                    label="Budynek"
                />
            </FilterBox>
            <FilterBox>
                <Select
                    options={optionsFloor}
                    isMulti
                    name="buildingFloor"
                    control={control}
                    label="Piętro"
                />
            </FilterBox>
            <FilterBox>
                <Select
                    options={optionsRoom}
                    isMulti
                    name="flatRoomsQuantity"
                    control={control}
                    label="Ilość pokoi"
                />
            </FilterBox>
            <FilterBox>
                <RangeSlider
                    label={`METRAŻ (m${'\u00B2'})`}
                    min={toNumber(yardageInitial[0])}
                    max={toNumber(yardageInitial[1])}
                    minValue={toNumber(yardage[0])}
                    maxValue={toNumber(yardage[1])}
                    onChange={handleOnYardageChange}
                />
                <InputWrapper>
                    <InputNumber
                        text="od"
                        value={yardage[0]}
                        onChange={handleOnMinYardage}
                        onBlur={handleOnMinBlurYardage}
                    />
                    <InputNumber
                        text="do"
                        value={yardage[1]}
                        onChange={handleOnMaxYardage}
                        onBlur={handleOnMaxBlurYardage}
                    />
                </InputWrapper>
            </FilterBox>
            <FilterBox>
                <RangeSlider
                    label="CENA (zł)"
                    min={toNumber(priceInitial[0])}
                    max={toNumber(priceInitial[1])}
                    minValue={toNumber(price[0])}
                    maxValue={toNumber(price[1])}
                    onChange={handleOnPriceChange}
                />
                <InputWrapper>
                    <InputNumber
                        text="od"
                        value={price[0]}
                        onChange={handleOnMinPriceChange}
                        onBlur={handleOnMinBlurPrice}
                    />
                    <InputNumber
                        text="do"
                        value={price[1]}
                        onChange={handleOnMaxPriceChange}
                        onBlur={handleOnMaxBlurPrice}
                    />
                </InputWrapper>
            </FilterBox>
            <FilterBox>
                <Label>Zalety</Label>
                <ButtonsWrapper>
                    <Button
                        onClick={handleOnGardenClick}
                        isActive={isActiveButton(facilities, 'Ogródek')}
                    >
                        <IconWrapper
                            isActive={isActiveButton(facilities, 'Ogródek')}
                        >
                            <Tree />
                        </IconWrapper>
                        Ogródek{' '}
                    </Button>
                    <Button
                        onClick={handleOnBalconClick}
                        isActive={isActiveButton(facilities, 'Balkon')}
                    >
                        <IconWrapper
                            isActive={isActiveButton(facilities, 'Balkon')}
                        >
                            <Balcon />
                        </IconWrapper>
                        Balkon{' '}
                    </Button>
                </ButtonsWrapper>
            </FilterBox>
        </FiltersBox>
    );
};
