import { TableFilters } from 'components/TableFilters/TableFilters';
import { FlatsTable } from 'components/Tables/FlatsTable';
import React, { useState, useEffect } from 'react';
import { useTheme } from 'layout/ThemeContext';
import { H2, SecondaryButton } from 'styles/Typography.styled';
import {
    FlatsWrapper,
    SectionTitleWrapper,
    TableSectionWrapper,
    FiltersWrapper,
    TableWrapper,
    ClearFiltersButton,
    Text,
    ButtonsWrapper,
} from './FlatsList.styled';
import { Cross } from 'shared/svg/cross';
import { SingleFlatType } from 'types/CommonTypes';
import { findMax, findMin, findOptions } from 'utils/filters';

export interface FlatsListProps {
    data: SingleFlatType[];
    investmentSlug: string;
}

export const FlatsList = ({ data, investmentSlug }: FlatsListProps) => {
    const { building, floor, room, yardage, price, facilities } = useTheme();
    const [isClearButtonShow, setClearButtonShow] = useState(false);
    const [clearFiltersActive, setClearFiltersActive] = useState(false);
    const buildingInitial = findOptions(data, 'investmentBuilding');
    const floorInitial = findOptions(data, 'buildingFloor');
    const roomInitial = findOptions(data, 'flatRoomsQuantity');
    const yardageInitial = [
        findMin(data, 'flatArea'),
        findMax(data, 'flatArea'),
    ];
    const priceInitial = [
        findMin(data, 'flatPrice'),
        findMax(data, 'flatPrice'),
    ];
    const facilitiesInitial = ['Ogródek', 'Balkon'];

    useEffect(() => {
        const equals = (filter: any, initial: any) =>
            filter?.length === initial?.length &&
            filter?.every((v: any, i: any) => v === initial[i]);

        const equalFilters =
            equals(building, buildingInitial) &&
            equals(floor, floorInitial) &&
            equals(room, roomInitial) &&
            equals(yardage, yardageInitial) &&
            equals(price, priceInitial) &&
            equals(facilities, facilitiesInitial);
        equalFilters ? setClearButtonShow(false) : setClearButtonShow(true);
    }, [building, floor, room, yardage, price, facilities]);

    const handleClearFilters = () => {
        setClearFiltersActive((prev) => !prev);
    };

    return (
        <FlatsWrapper>
            <SectionTitleWrapper>
                <H2>Lista mieszkań</H2>

                <ButtonsWrapper>
                    {isClearButtonShow && (
                        <ClearFiltersButton onClick={handleClearFilters}>
                            <Cross />
                            <Text>Wyczyść filtry</Text>
                        </ClearFiltersButton>
                    )}
                    <SecondaryButton to={'/' + investmentSlug + '/garaze'}>
                        Zobacz garaże i komórki lokatorskie
                    </SecondaryButton>{' '}
                </ButtonsWrapper>
            </SectionTitleWrapper>
            <FiltersWrapper>
                <TableFilters
                    buildingInitial={buildingInitial}
                    roomInitial={roomInitial}
                    floorInitial={floorInitial}
                    yardageInitial={yardageInitial}
                    priceInitial={priceInitial}
                    facilitiesInitial={facilitiesInitial}
                    clearFiltersActive={clearFiltersActive}
                    onHandleClearFilters={handleClearFilters}
                />
            </FiltersWrapper>
            <TableSectionWrapper>
                <TableWrapper>
                    <FlatsTable investmentSlug={investmentSlug} data={data} />
                </TableWrapper>
            </TableSectionWrapper>
        </FlatsWrapper>
    );
};
