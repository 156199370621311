import { useTheme } from 'layout/ThemeContext';
import { useEffect, useState } from 'react';
import { SingleFlatType } from 'types/CommonTypes';

export const useFilteredData = (flatsData: SingleFlatType[]) => {
    const {
        building,
        floor,
        room,
        yardage,
        price,
        facilities,
        sortBy,
        setNumberFilter,
        setActiveFilter,
    } = useTheme();
    const [initialBuilding] = useState(building);
    const [initialFloor] = useState(floor);
    const [initialRoom] = useState(room);
    const [initialYardage] = useState(yardage);
    const [initialPrice] = useState(price);
    const [initialFacilities] = useState(facilities);
    const [number, setNumber] = useState(new Set<string>());

    const equalsArray = (a: string[], b: string[]) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter((e) => e === v).length;
            const bCount = b.filter((e) => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    };
    const equalsString = (filter: string, initial: string) =>
        filter === initial;

    useEffect(() => {
        !equalsArray(initialBuilding, building) &&
            setNumber((prev) => new Set(prev.add('building')));

        equalsArray(initialBuilding, building) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'building')),
            );

        !equalsArray(initialFloor, floor) &&
            setNumber((prev) => new Set(prev.add('floor')));

        equalsArray(initialFloor, floor) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'floor')),
            );

        !equalsArray(initialRoom, room) &&
            setNumber((prev) => new Set(prev.add('room')));

        equalsArray(initialRoom, room) &&
            setNumber((prev) => new Set([...prev].filter((x) => x !== 'room')));

        !equalsArray(initialYardage, yardage) &&
            setNumber((prev) => new Set(prev.add('yardage')));

        equalsArray(initialYardage, yardage) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'yardage')),
            );

        !equalsArray(initialPrice, price) &&
            setNumber((prev) => new Set(prev.add('price'))) &&
            setActiveFilter(false);

        equalsArray(initialPrice, price) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'price')),
            );
        !equalsArray(initialFacilities, facilities) &&
            setNumber((prev) => new Set(prev.add('facilities')));

        equalsArray(initialFacilities, facilities) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'facilities')),
            );
        !equalsString('Domyślnie', sortBy) &&
            setNumber((prev) => new Set(prev.add('sortBy')));

        equalsString('Domyślnie', sortBy) &&
            setNumber(
                (prev) => new Set([...prev].filter((x) => x !== 'sortBy')),
            );
    }, [building, floor, room, yardage, price, facilities, sortBy]);

    const minPrice = parseInt(
        price[0].replace(
            /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g,
            '',
        ),
    );
    const maxPrice = parseInt(
        price[1].replace(
            /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g,
            '',
        ),
    );

    const sortData = (sortBy: string) => {
        let filterData: SingleFlatType[] = [];
        switch (sortBy) {
            case 'Najtańszych':
                filterData = flatsData.slice().sort((x, y) => {
                    return x.flatPrice - y.flatPrice;
                });
                break;
            case 'Najdroższych':
                filterData = flatsData.slice().sort((x, y) => {
                    return y.flatPrice - x.flatPrice;
                });
                break;
            case 'Najmniejszej liczby pokoi':
                filterData = flatsData.slice().sort((x, y) => {
                    return x.flatRoomsQuantity - y.flatRoomsQuantity;
                });
                break;
            case 'Najwiekszej liczby pokoi':
                filterData = flatsData.slice().sort((x, y) => {
                    return y.flatRoomsQuantity - x.flatRoomsQuantity;
                });
                break;
            case 'Najmniejszego metrażu':
                filterData = flatsData.slice().sort((x, y) => {
                    return x.flatArea - y.flatArea;
                });
                break;
            case 'Największego metrażu':
                filterData = flatsData.slice().sort((x, y) => {
                    return y.flatArea - x.flatArea;
                });
                break;
            default:
                filterData = [...flatsData];
                break;
        }
        return filterData;
    };
    const sortedData = sortData(sortBy);

    const filterByBuilding = sortedData.filter((item1) =>
        building.some((item2) => item2 === item1.investmentBuilding),
    );
    const filterByFloor = filterByBuilding.filter((item1) =>
        floor.some((item2) => item2 === item1.buildingFloor),
    );
    const filterByRoom = filterByFloor.filter((item1) =>
        room.some((item2) => +item2 === item1.flatRoomsQuantity),
    );
    const filterByFacilities = filterByRoom.filter((item1) =>
        facilities.some((item2) => item2 === item1.flatAmenities),
    );
    const filterByPrice = filterByFacilities.filter((item1) =>
        price.some(
            () => minPrice <= item1.flatPrice && item1.flatPrice <= maxPrice,
        ),
    );
    const filteredData = filterByPrice.filter((item1) =>
        yardage.some(
            () =>
                parseInt(yardage[0]) <= item1.flatArea &&
                item1.flatArea <= parseInt(yardage[1]),
        ),
    );

    useEffect(() => {
        setNumberFilter(number.size);
    }, [number]);

    return {
        filteredData,
    };
};
