import React, { useState } from 'react';
import { MobileFilters } from 'components/MobileFilters/MobileFilters';
import { FlatsListProps } from 'sections/FlatsList/FlatsList';
import { ShowFiltersButton } from 'shared/svg/filters';
import { findMax, findMin, findOptions } from 'utils/filters';
import {
    HeroButton,
    HeroTitle,
    IconWrapper,
    MieszkaniaHeroWrapper,
    Number,
} from './FlatsHeroMobile.styled';
import { useTheme } from 'layout/ThemeContext';

export const FlatsHeroMobile = ({ data, investmentSlug }: FlatsListProps) => {
    const [showModal, setShowModal] = useState(false);

    const buildingInitial = findOptions(data, 'investmentBuilding');
    const floorInitial = findOptions(data, 'buildingFloor');
    const roomInitial = findOptions(data, 'flatRoomsQuantity');
    const yardageInitial = [
        findMin(data, 'flatArea'),
        findMax(data, 'flatArea'),
    ];
    const priceInitial = [
        findMin(data, 'flatPrice'),
        findMax(data, 'flatPrice'),
    ];
    const facilitiesInitial = ['Ogródek', 'Balkon'];
    const { numberFilter, sortBy } = useTheme();

    return (
        <MieszkaniaHeroWrapper>
            <HeroTitle>Lista mieszkań</HeroTitle>
            <HeroButton to={'/' + investmentSlug + '/garaze'}>
                Zobacz garaże i komórki lokatorskie
            </HeroButton>
            <IconWrapper onClick={() => setShowModal(true)}>
                {numberFilter !== 0 && <Number>{numberFilter}</Number>}
                <ShowFiltersButton />
            </IconWrapper>
            {showModal && (
                <MobileFilters
                    buildingInitial={buildingInitial}
                    roomInitial={roomInitial}
                    floorInitial={floorInitial}
                    yardageInitial={yardageInitial}
                    priceInitial={priceInitial}
                    facilitiesInitial={facilitiesInitial}
                    setShowModal={setShowModal}
                />
            )}
        </MieszkaniaHeroWrapper>
    );
};
